<template>
  <div>
    <div
      class="card mb-3"
      style="width: 100%"
      v-loading="loading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="card-header bg-light">
        <h3 class="title fw-normal mb-0 p-2">Transfer Account Ownership </h3>
      </div>
      <div class="stats-list side-menu">
          <div class="single-stat">
            <el-row type="flex">
              <el-col>
                <el-form :model="ruleForm" label-position="top"  :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
                    <span>
                        Your account is currently associated with <b>{{ getAuthenticatedUser.email }}</b>. To initiate a transfer, simply fill out the form and authenticate both accounts. Once completed, your account assets will be ready for transfer.
                    </span>
                    <el-form-item label="New Account Email"  prop="email">
                      <el-input v-model="ruleForm.email"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="Resources" prop="resource">
                      <el-radio-group v-model="ruleForm.resource" size="mini">
                        <el-radio-button value="OnlyThisWorkspace" label="Only This Workspace"></el-radio-button>
                        <el-radio-button value="AllWorkspaces" label="All Workspaces"></el-radio-button>
                      </el-radio-group>
                    </el-form-item> -->
                    <el-form-item>
                      <el-button type="primary" @click="submitForm('ruleForm')" size="mini" style="height: 35px">Transfer</el-button>
                      <el-button @click="resetForm('ruleForm')" size="mini" style="height: 35px">Reset</el-button>
                    </el-form-item>
                  </el-form>
              </el-col>
            </el-row>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import SysAxios from "@/config/axios";
import appConfig from '@/config/app';
export default {
  name: "Dashboard-Stats",
  async mounted() {
    this.loading = true;
    // await this.fetchUserdashboardStatics();
    // await this.setData();
    this.loading = false;
  },
  computed: {
    ...mapGetters("auth", ["getAuthenticatedUser", "getActiveWorkspace"]),
    ...mapGetters("settings", [
      "getUserDashboardStastics",
      "getUpdateUserDashboardStaticsStatus",
    ]),
  },
  methods: {
    submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log(this.ruleForm);
            let inputValue='';
            Swal.fire({
                    title: "Authentication",
                    input: "password",
                    inputLabel: "Please enter your password",
                    inputPlaceholder: "Please enter your password",
                    inputValue,
                    inputAttributes: {
                      autocapitalize: "off"
                    },
                    confirmButtonText: "Transfer",
                    showLoaderOnConfirm: true,
                    showCancelButton: true,
                    allowOutsideClick:false,
                    allowEscapeKey:false,
                    inputValidator: (value) => {
                    if (!value) {
                      Swal.hideLoading();
                      return "Invalid Password";
                    }
                    let valid=/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/.test(value)
                    if(!valid)
                    {
                      Swal.hideLoading();
                      return "Must have one Uppercase(A-Z) letter,one lowercase(a-z) letter ,one number(0-9) and one symbol";
                    }                    
                  },
                    preConfirm: async (value) => {
                      try{
                        let payload={...this.ruleForm,key:value};
                        let response=await SysAxios.post('/transferOwnership',payload);
                        Swal.fire({
                          icon:'success',
                          title:'',
                          text:"OTP has been sent to both email ids",
                          confirmButtonText: "Ok",
                          showLoaderOnConfirm: true,
                          showCancelButton: false,
                          allowOutsideClick:false,
                          allowEscapeKey:false,
                        }).then(()=>{
                          this.$router.push({name:'AppOwnerTransferOTPAuth',params:{requestId:response.data.referenceId}})
                        })   
                      }
                      catch(err)
                      {
                        console.log(err);
                        Swal.showValidationMessage(err.response.data.message || err.message);
                      }

                    },
                  }).then((result) => {
                    console.log(result);
                  });


          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
  },
  data() {
    return {
        loading: false,
        serverBaseURL: appConfig.AUTHFAST_BASE_URL,
        ruleForm: {
            email: '',
            resource: 'OnlyThisWorkspace'

        },
        rules: {
          email: [
            { required: true, message: 'Please input email address', trigger: 'blur' },
            { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }
          ],
          resource: [
            { required: true, message: 'Please select activity resource', trigger: 'change' }
          ]
        }
    };
  },
};
</script>

<style lang="scss" scoped>
.side-menu{
  height: 580px;
  scrollbar-width:thin;
  border-top:1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
  margin-right: 5px;
  
}
.side-menu::-webkit-scrollbar {
   width: 0.7em;
}
.side-menu::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    background: #e1e1e1;
    -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9); 
}
.side-menu::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #F5F5F5; 
    background-color: #F5F5F5;

}
.stats-list {
  .single-stat {
    padding: 1em 1.5em;
  }
  .stat-item:last-child {
    .stat-divider {
      display: none;
    }
  }
}
.el-button--mini {
  font-size: 14px;
  font-weight: 500;
}
</style>